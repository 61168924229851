import React from "react";
import "../Contact/Contact.css";
import Images from "../../images/Images";

const Contact = () => {
  return (
    <>
      <div id="contact" className="contact__container">
        <h2>Contact</h2>
        <h1>Let's Keep In Touch</h1>
        <hr />
        <div className="contact__inner">
          <div className="contact__left">
            <div className="contact__inner-left">
              <div className="contact__left-inner-left-content">
                <p>
                  <img src={Images.email} alt="" />
                </p>
              </div>
              <div className="contact__left-inner-right-content">
                <h4>Email Address</h4>
                <p>robjaydes@gmail.com</p>
              </div>
            </div>
            <div className="contact__inner-left">
              <div className="contact__left-inner-left-content">
                <p>
                  <img src={Images.phone} alt="" />
                </p>
              </div>
              <div className="contact__left-inner-right-content">
                <h4>Contact Number</h4>
                <p>09692020901 / 09690926388</p>
              </div>
            </div>
            <div className="contact__inner-left">
              <div className="contact__left-inner-left-content">
                <p>
                  <img src={Images.location} alt="" />
                </p>
              </div>
              <div className="contact__left-inner-right-content">
                <h4>Home Address</h4>
                <p>Davao City, Philippines</p>
              </div>
            </div>
          </div>

          <div className="contact__right">
            <form name="contact-form" method="POST" data-netlify="true">
              <input type="hidden" name="form-name" value="contact-form" />
              <div className="contact__form-upper">
                <input type="text" placeholder="Your Name..." name="name" />
                <input
                  type="text"
                  placeholder="Your Email Address..."
                  name="email"
                />
              </div>
              <textarea
                placeholder="Your Message..."
                name="message"
                cols="30"
                rows="8"
                required=""
              ></textarea>

              <button className="contact__btn" type="submit">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;

import React from 'react';
import Hero from './components/Hero/Hero';
import Projects from './components/Projects/Projects';
import About from './components/About/About';
import Services from './components/Services/Services';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import './App.css'

function App() {
  return (
    <React.Fragment>
    <div className='container'>
    <Hero />
    <Projects />
    <About />
    <Services />
    <Contact />
   </div>
   <Footer />
    </React.Fragment>
  );
}

export default App;

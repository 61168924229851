import React from "react";
import Images from "../../images/Images";
import "../Projects/Projects.css";

const Projects = () => {
  const projectData = [
    {
      title: "VA GIFTS PHILIPPINES",
      imgSrc: Images.vagiftsphilippines,
      liveDemoLink: "https://vagiftsphilippines.com/",
    },
    {
      title: "GIFTS DAVAO",
      imgSrc: Images.giftasticdelivery,
      liveDemoLink: "https://giftasticdelivery.com/",
    },
    {
      title: "PROXIMITY OUTSOURCING",
      imgSrc: Images.proximityoutsourcing,
      liveDemoLink: "https://proximityoutsourcing.com/",
    },
    {
      title: "ALBATROSS GOLD MARKETING",
      imgSrc: Images.albatrossgolfmarketing,
      liveDemoLink: "https://albatrossgolfmarketing.com/",
    },
    {
      title: "PERSONAL WEBSITE",
      imgSrc: Images.karlasingson,
      liveDemoLink: "https://karlasingson.com/",
    },
    {
      title: "HEY COLLABS",
      imgSrc: Images.heycollabs,
      liveDemoLink: "https://heycollabs.com/",
    },
    {
      title: "THE BLIND FACTORY",
      imgSrc: Images.blindfactory,
      liveDemoLink: "https://staging-gunarifo.kinsta.cloud/",
    },
    {
      title: "N90",
      imgSrc: Images.n90,
      liveDemoLink: "https://app.n90.com.au/",
    },
    {
      title: "BIOPHARMA",
      imgSrc: Images.biopharma,
      liveDemoLink: "https://biopharma.bz/",
    },
    {
      title: "SPECIALIST SKATING",
      imgSrc: Images.specialistskating,
      liveDemoLink: "https://iceskate.co.nz/",
    },
    {
      title: "MAD SCIENTIST",
      imgSrc: Images.madscientisstg,
      liveDemoLink: "https://madscientisstg.wpengine.com/",
    },
    {
      title: "CONSULT HUCKLEBERRY",
      imgSrc: Images.consulthuckleberry,
      liveDemoLink: "https://consulthuckleberry.com/",
    },
    {
      title: "FREE GAMES APP",
      imgSrc: Images.freetogame,
      liveDemoLink: "https://rjd-freetogame.netlify.app/",
    },
    {
      title: "COVID-19 TRACKER",
      imgSrc: Images.tracker,
      liveDemoLink: "https://rjd-covid-19-tracker.netlify.app/",
    },
    {
      title: "TODO LIST APP",
      imgSrc: Images.todo,
      liveDemoLink: "https://rjd-todo-list.netlify.app/",
    },
    {
      title: "CRYPTO TRACKER APP",
      imgSrc: Images.crypto,
      liveDemoLink: "https://rjd-crypto-tracker.netlify.app/",
    },
    // Add more project details in a similar format
  ];

  return (
    <>
      <div className="projects">
        <h1 className="projects__title">PROJECTS</h1>
        <div className="projects__container">
          {projectData.map((project, index) => (
            <div className="projects__card" key={index}>
              <a rel="noreferrer" target="_blank" href={project.liveDemoLink}>
                <img className="projects__img" src={project.imgSrc} alt="" />
                <div className="projects__card-con">
                  <p className="projects__card-title">{project.title}</p>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Projects;

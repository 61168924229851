import React from "react";
import "../Hero/Hero.css";
import "animate.css";
import Images from "../../images/Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faGithub,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Hero = () => {
  const fb = <FontAwesomeIcon icon={faFacebook} />;
  const git = <FontAwesomeIcon icon={faGithub} />;
  const link = <FontAwesomeIcon icon={faLinkedin} />;
  return (
    <>
      <div className="hero__container">
        <p className="hero__txt animate__animated animate__zoomInDown">
          Hi there
        </p>
        <h1 className="hero__name animate__animated animate__jackInTheBox  animate__slow">
          I'm Robby
          <p className="animate__animated animate__backInRight">.</p>
        </h1>
        <h3 className="hero__job animate__animated animate__rollIn">
          Sr. Front-End/ Wordpress Developer
        </h3>
        <a className="hero__btn" href="#contact">
          Let's Talk
        </a>
      </div>
      <div className="hero__links-con">
        <a
          target={"_blank"}
          rel="noreferrer"
          href="https://www.facebook.com/ybboratoslacsed"
        >
          {fb}
        </a>
        <a
          target={"_blank"}
          rel="noreferrer"
          href="https://github.com/RobbyJayce"
        >
          {git}
        </a>
        <a
          target={"_blank"}
          rel="noreferrer"
          href="https://www.linkedin.com/in/robbyjayce/"
        >
          {link}
        </a>
      </div>
      <img className="hero__bg" src={Images.logoBG} alt="" />
    </>
  );
};

export default Hero;

const Images = {
  logoBG: require("../images/herobg.webp"),
  robPic: require("../images/robby.webp"),
  freetogame: require("../images/freetogame.webp"),
  todo: require("../images/todo.webp"),
  tracker: require("../images/tracker.webp"),
  crypto: require("../images/crypto.webp"),
  albatrossgolfmarketing: require("../images/albatrossgolfmarketing.webp"),
  biopharma: require("../images/biopharma.webp"),
  consulthuckleberry: require("../images/consulthuckleberry.webp"),
  giftasticdelivery: require("../images/giftasticdelivery.webp"),
  blindfactory: require("../images/blindfactory.webp"),
  n90: require("../images/n90.webp"),
  heycollabs: require("../images/heycollabs.webp"),
  karlasingson: require("../images/karlasingson.webp"),
  proximityoutsourcing: require("../images/proximityoutsourcing.webp"),
  specialistskating: require("../images/specialistskating.webp"),
  vagiftsphilippines: require("../images/vagiftsphilippines.webp"),
  madscientisstg: require("../images/madscientisstg.webp"),
  serviceimg1: require("../images/frontend.webp"),
  serviceimg2: require("../images/responsive.webp"),
  serviceimg3: require("../images/team.webp"),
  phone: require("../images/phone.webp"),
  location: require("../images/location.webp"),
  email: require("../images/email.webp"),
};

export default Images;

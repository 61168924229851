import React from "react";
import "../Services/Services.css";
import Images from "./../../images/Images";

const Services = () => {
  return (
    <>
      <div className="services__container">
        <div className="services__top-container">
          <p className="services__title">Services</p>
          <p className="services__txt">What I Do</p>
        </div>

        <hr />
        <div className="services__cards-con">
          <div className="services__card">
            <img src={Images.serviceimg1} alt="" />
            <p className="services__card-title">Front-End Development</p>
            <p className="services__card-txt">
              Convert your mockups and ui prototypes into a functional and
              working website that creates an interaction and user experience.
            </p>
          </div>
          <div className="services__card">
            <img src={Images.serviceimg2} alt="" />
            <p className="services__card-title">Responsive Website</p>
            <p className="services__card-txt">
              Develop a responsive website layout that automatically adjusts and
              adapts to any device screen size for a better user experience.
            </p>
          </div>
          <div className="services__card">
            <img src={Images.serviceimg3} alt="" />
            <p className="services__card-title">Team Collaboration</p>
            <p className="services__card-txt">
              Can perform as part of a team and has the necessary skills to help
              others to complete a task in the most effective and efficient way.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;

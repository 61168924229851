import React from "react";
import "../About/About.css";
import Images from "./../../images/Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faGithub,
  faLinkedin,
  faCodepen,
} from "@fortawesome/free-brands-svg-icons";

const About = () => {
  const fb = <FontAwesomeIcon icon={faFacebook} />;
  const git = <FontAwesomeIcon icon={faGithub} />;
  const link = <FontAwesomeIcon icon={faLinkedin} />;
  const code = <FontAwesomeIcon icon={faCodepen} />;
  return (
    <>
      <div className="about__container">
        <div className="about__img-con-new">
          <img src={Images.robPic} alt="" />
        </div>
        <div className="about__top-content">
          <div className="about__img-con">
            <img src={Images.robPic} alt="" />
          </div>
          <div className="about__top-inner-content">
            <p className="about_top-inner-title">
              <span className="code-pen">{code}</span>About Me
            </p>
            <p className="about_top-inner-name">Robby Jayce Descalsota</p>
            <p className="about_top-inner-txt">
              Driven by a passion for web development, I thrive on tackling new
              challenges and creating captivating digital experiences. With a
              keen eye for detail and a commitment to staying ahead of industry
              trends, I specialize in crafting visually stunning and
              user-friendly websites that leave a lasting impression. Let's
              collaborate to bring your online vision to life and elevate your
              brand's presence on the web.
            </p>
            <div className="about__icon-con">
              <a
                rel="noreferrer"
                target={"_blank"}
                href="https://www.facebook.com/ybboratoslacsed"
              >
                {fb}
              </a>
              <a
                rel="noreferrer"
                target={"_blank"}
                href="https://github.com/RobbyJayce"
              >
                {git}
              </a>
              <a
                rel="noreferrer"
                target={"_blank"}
                href="https://www.linkedin.com/in/robbyjayce/"
              >
                {link}
              </a>
            </div>
          </div>
        </div>
        <div className="about__bottom-content">
          <div className="about__left-content">
            <p className="about__left-content-title">
              <span className="code-pen">{code}</span>Education
            </p>
            <p className="about__left-content-degree">
              Bachelor of Science in Information Technology (BSIT)
            </p>
            <p className="about__left-content-school">
              Holy Cross of Davao College
            </p>
            <p className="about__left-content-txt">
              Davao City, Philippines
              <br />
              2014 - 2018 (Completed w/Degree)
            </p>
          </div>
          <div className="about__right-content">
            <p className="about__right-title">
              {" "}
              <span className="code-pen">{code}</span>Udemy Certifications
            </p>
            <ul>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="https://www.udemy.com/certificate/UC-40f5a1e3-1484-4ace-8e84-f9d3c2a809e1/"
                >
                  Complete WordPress Theme & Plugin Development Course
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="https://www.udemy.com/certificate/UC-0c49885a-3f0c-43dc-ac65-3acb5f860d3e/"
                >
                  Become a WordPress Developer: Unlocking Power With Code
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="https://www.udemy.com/certificate/UC-53f8eace-8c52-43fd-afef-6f4ddceb8be6/"
                >
                  PHP for Beginners - Become a PHP Master - CMS Project
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="https://www.udemy.com/certificate/UC-b1ae8f79-2484-465f-a3f1-5ded27a60bc5/"
                >
                  React - The Complete Guide (incl Hooks, React Router, Redux)
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="https://www.udemy.com/certificate/UC-a5ecc246-2437-48e1-9791-229d7fd4e4b9/"
                >
                  Complete WordPress Developer Course 2023 - Plugins & Themes
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="https://www.udemy.com/certificate/UC-7d0c05c1-1242-474e-a0bf-4f838df3c0bc/"
                >
                  The Complete JavaScript Course 2023: From Zero to Expert!
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="https://www.credential.net/f1939e47-cc97-4fe3-b35a-a1a24c39cfcb#gs.uc2ocz"
                >
                  Cisco Certified Network Associate (CCNA 200-301)
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="https://jmp.sh/VYH2niA"
                >
                  The Ultimate HTML5 & CSS3 Series 1
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="https://jmp.sh/GlZ9Wrz"
                >
                  The Ultimate HTML5 & CSS3 Series 2
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="https://jmp.sh/eCdloJC"
                >
                  The Ultimate HTML5 & CSS3 Series 3
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="https://jmp.sh/pXrhlZd"
                >
                  The Ultimate JavaScript Mastery Series - Part 1
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="https://jmp.sh/oeOrgdi"
                >
                  The Ultimate JavaScript Mastery Series - Part 2
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="https://jmp.sh/xaMciOp"
                >
                  Mastering React
                </a>
              </li>
              <li>
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="https://jmp.sh/Id6Vpic"
                >
                  The Ultimate Git Course
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="about__footer">
          <p className="about__footer-title">
            <span className="code-pen">{code}</span>Skills
          </p>
          <div className="about__footer-img-con">
            <a href="https://devdocs.io/html/" rel="noreferrer" target="_blank">
              <img
                src="https://cdn.sanity.io/images/0rcnduwq/production/01723cf9b7f9107a01f125c7663d445e95e086b8-1773x2500.svg"
                alt=""
              />
            </a>
            <a
              className="about__footer-logo"
              href="https://devdocs.io/css/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="https://cdn.sanity.io/images/0rcnduwq/production/a2edafa65f431ce4bc7689b6b69e71e856956484-128x128.svg?rect=0,0,122,124"
                alt=""
              />
            </a>
            <a
              href="https://devdocs.io/javascript/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="https://cdn.sanity.io/images/0rcnduwq/production/bed315853ebbd8e8266cc25051da41cd4db36530-79x111.svg"
                alt=""
              />
            </a>

            <a
              href="https://reactjs.org/docs/getting-started.html"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="https://cdn.sanity.io/images/0rcnduwq/production/9f80fdcd08c5c8f67134b73dbe06fbb3671cf898-128x128.svg"
                alt=""
              />
            </a>
            <a
              href="https://getbootstrap.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/5968/5968672.png"
                alt=""
              />
            </a>
            <a href="https://git-scm.com/doc" rel="noreferrer" target="_blank">
              <img
                src="https://cdn.sanity.io/images/0rcnduwq/production/2120a985e4270a7f558f97aa16c4faa2c1e5656f-128x128.svg"
                alt=""
              />
            </a>
            <a
              href="https://helpx.adobe.com/photoshop/user-guide.html"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/5968/5968520.png"
                alt=""
              />
            </a>
            <a href="https://www.php.net/" rel="noreferrer" target="_blank">
              <img
                className="about__logo-php"
                src="https://img.icons8.com/dusk/2x/php-logo.png"
                alt=""
              />
            </a>
            <a href="https://www.mysql.com/" rel="noreferrer" target="_blank">
              <img
                className="about__logo-mysql"
                src="https://img.icons8.com/color/2x/mysql-logo.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
